import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import * as styles from "../../styles/plants.module.css"

export default function Plants({ data }) {

  const group = (plants) => {
    let resultObj = {};

    for (let i = 0; i < plants.length; i++) {
      let plant = plants[i]
      let currentWord = plant.name;
      let firstChar = currentWord[0].toLowerCase();
      let innerArr = [];

      if (resultObj[firstChar] === undefined) {
        innerArr.push(plant);
        resultObj[firstChar] = innerArr
      } else {
        resultObj[firstChar].push(plant)
      }
    }

    return resultObj
  }

  const plants = group(data.allPlantsDataJson.nodes)

  const keys = Object.keys(plants)
  keys.sort()

  function uuidv4() {
    var u = '', i = 0;
    while (i++ < 36) {
      var c = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'[i - 1], r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      u += (c === '-' || c === '4') ? c : v.toString(16)
    }
    return u;
  }

  return (
    <Layout>
      <Seo title={'Piante'} description={'Scopri nuove piante per arredare il tuo appartamento o il tuo giardino.'} image={data.allPlantsDataJson.nodes[3].image.childImageSharp.gatsbyImageData.images.fallback.src} article={false} />

      <h1 className={styles.title}>Piante</h1>

      <div>
        {keys.map((key, i) => {
          const plantsSlice = plants[keys[i]]

          return (
            <div className={styles.group} key={uuidv4()}>
              <h2>{keys[i].toUpperCase()}</h2>

              <div className={styles.container}>
                {plantsSlice.map(plant => {
                  return (<Link to={'/piante/' + plant.slug} key={plant.plantId}>
                    <div className={styles.card}>
                      {plant.image.childImageSharp &&
                        <GatsbyImage image={plant.image.childImageSharp.gatsbyImageData} className={styles.image} alt={plant.name} />
                      }
                      <p className={styles.text}>{plant.name}</p>
                    </div>
                  </Link>)
                })}
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
query Plants {
  allPlantsDataJson {
    nodes {
      slug
      name
      plantId
      image {
				childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
}
`